import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyListPanelComponent } from './property-list-panel.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PropertyDefaultCardModule } from '../../modules/property-default-card/property-default-card.module';
import { TranslateModule } from '@ngx-translate/core';
import { SortOrderModule } from '../../../common/filters/sort-order/sort-order.module';
import { PagingModule } from '../../modules/paging/paging.module';

@NgModule({
  declarations: [PropertyListPanelComponent],
  imports: [CommonModule, ScrollingModule, PropertyDefaultCardModule, TranslateModule, SortOrderModule, PagingModule],
  exports: [PropertyListPanelComponent],
})
export class PropertyListPanelModule {}
