import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { delay, finalize, from, of } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { NominatimService } from './nominatim.service';

@Injectable({
  providedIn: 'root',
})
export class GenerateDemoDataService {
  private streets = require('assets/json/sk-streets.json');
  public generatedAddress: string[] = [];
  public generatedAddressWithGeo: any[] = [];

  constructor(private nominatimService: NominatimService) {}

  public generateAddress(): void {
    const addressesStr = localStorage.getItem('addresses');
    let addresses = [];

    if (!addressesStr?.length) {
      this.streets.forEach((street: string) => {
        this.generatedAddress.push(`${street}, ${this.getRandomNumber(1, 30)}`);
        this.generatedAddress.push(`${street}, ${this.getRandomNumber(1, 30)}`);
      });
      addresses = [...this.generatedAddress];
      localStorage.addresses = JSON.stringify(this.generatedAddress);
    } else {
      addresses = JSON.parse(addressesStr);
      this.generatedAddress = [...addresses];
    }

    const obc = from(this.generatedAddress)
      .pipe(
        delay(this.getRandomNumber(30, 120)),
        concatMap((q: string) => this.generateAddressWithGeo(q)),
        catchError((error) => {
          console.log('Error:', error);
          return of(null);
        }),
        finalize(() => {
          if (addresses.length) {
            localStorage.setItem('addresses', JSON.stringify(addresses));
          } else {
            localStorage.removeItem('addresses');
          }
          this.saveArrayToJsonFile('geo-addresses', this.generatedAddressWithGeo);
          console.log('Done!');
        }),
      )
      .subscribe((res: any) => {
        addresses.shift();
        if (res[0]?.address?.city ?? res[0]?.address?.town ?? res[0]?.address?.village) {
          this.generatedAddressWithGeo.push({
            address: res[0].address,
            fullAddress: res[0].display_name,
            city: res[0].address?.city ?? res[0].address?.town ?? res[0].address?.village,
            lat: res[0].lat,
            lon: res[0].lon,
          });
        }
      });
  }

  private saveArrayToJsonFile(name: string, data: any[]): void {
    try {
      const jsonData = JSON.stringify(data, null, 2);
      const blob = new Blob([jsonData], { type: 'application/json' });
      FileSaver.saveAs(blob, `${name}.json`);
    } catch (error) {
      console.error(error);
    }
  }

  private generateAddressWithGeo(q) {
    return this.nominatimService.searchAddress(q, 1, 0);
  }

  private getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
