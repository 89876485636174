import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { GEOIP, HEALTH } from '../endpoints';

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  constructor(private http: HttpClient) {}

  public getUserInfo() {
    return this.http.get(HEALTH, { observe: 'response' });
  }

  public getCityUser(ip?: string) {
    if (ip) {
      return this.http.get(`${GEOIP}/${ip}?token=${environment.geoIpServiceToken}`, { observe: 'response' });
    }
    return this.http.get(`${GEOIP}?token=${environment.geoIpServiceToken}`, { observe: 'response' });
  }
}
