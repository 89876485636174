import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LAST_VIEWS_PROPERTIES } from '../endpoints';

@Injectable({
  providedIn: 'root',
})
export class LastViewedPropertiesService {
  constructor(private http: HttpClient) {}

  public getProperties(ids: number[]) {
    return this.http.post(LAST_VIEWS_PROPERTIES, { ids });
  }
}
