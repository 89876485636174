import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '@service';
import * as HeaderActions from '@store';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { REFRESH_TOKEN } from '../shared/endpoints';

@Injectable()
export class AuthInterceptors implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService,
    private store: Store,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string | null = null;

    if (isPlatformBrowser(this.platformId)) {
      token = this.authService.getToken();

      setTimeout(() => {
        this.store.dispatch(HeaderActions.updateLoggedState({ data: token !== null }));
      });
    }
    const authRequest = token ? request.clone({ setHeaders: { Authorization: `Bearer ${token}` } }) : request;

    return next.handle(authRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !request.url.includes(REFRESH_TOKEN)) {
          return from(this.authService.refreshToken()).pipe(
            switchMap((newToken) => {
              this.authService.saveToken(newToken);
              const newAuthRequest = request.clone({
                setHeaders: { Authorization: `Bearer ${newToken.access_token}` },
              });
              this.store.dispatch(HeaderActions.updateLoggedState({ data: true }));
              return next.handle(newAuthRequest);
            }),
            catchError((refreshError) => {
              this.store.dispatch(HeaderActions.updateLoggedState({ data: false }));
              this.authService.deleteToken();
              // this.router.navigate(['/']);
              return throwError(refreshError);
            }),
          );
        } else {
          return throwError(error);
        }
      }),
    );
  }
}
