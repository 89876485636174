import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MapSettingService {
  readonly defaultStyleMap = {
    color: '#00a046',
    fillColor: '#00a046',
    fillOpacity: 0.01,
    width: 5,
  };
  readonly defaultStyleClusters = {
    polygonOptions: {
      color: '#00a046',
      opacity: 1,
      fillColor: '#00a046',
      fillOpacity: 0.4,
      width: 4,
    },
    spiderLegPolylineOptions: {
      color: 'trasparent',
      fillColor: 'trasparent',
      fillOpacity: 0,
      width: 0,
    },
    removeOutsideVisibleBounds: false,
    spiderfyOnMaxZoom: false,
    maxClusterRadius: 120,
    animateAddingMarkers: false,
    animate: true,
    disableClusteringAtZoom: 18,
    chunkedLoading: true,
  };
  readonly defaultSettingPopup = {
    autoPan: false,
    keepInView: true,
    autoClose: true,
    closeButton: false,
    cursor: true,
  };
  readonly defaultMinZoomMap = 10;
  readonly defaultMaxZoomMap = 21;
  readonly defaultZoomMap = 15;
  readonly defaultParametersMap = { lon: '17.1645049', lat: '48.1493142', city: 'Bratislava' };
  readonly streetZoomMap = 16;
  readonly defaultImage = '/assets/image/default-img.jpg';
}
