import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { regExp } from 'app/shared/helpers';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  helpMenu = [
    { name: 'Terms of Use', path: '/terms' },
    { name: 'Privacy Policy', path: '/privacy-policy' },
    { name: 'Site Map', path: '/sitemap' },
    { name: 'Search properties', path: '/search' },
  ];

  // later we will adding services that like: Photographer, Cleaner, Layer, etc...
  serviceMenu = [];

  hidden = false;
  hiddenFooterOnPage = [
    '/search',
    '/profile/saved-searches',
    '/profile/favourites',
    '/profile/account',
    '/profile/settings',
    '/profile/calendar',
    '/token-health',
    '/forgot-password',
    // '/myzex',
    // '/myzex/saved-searches',
    // '/myzex/saved-homes',
    // '/myzex/your-homes',
    // '/myzex/account-settings',
  ];

  constructor(private route: Router) {}

  ngOnInit() {
    this.route.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((res: NavigationEnd) => {
      const pageUrl = this.hiddenFooterOnPage.find(
        (check) => check === res.url.replace(new RegExp(regExp.urlPageWithoutParam), ''),
      );
      this.hidden = !!pageUrl;
    });
  }
}
