import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { NominatimService } from '@service';
import { IInitSearchParams, ISearchParams } from '@interfaces';
import * as SearchPageActions from '../../store/actions/search-page.actions';
import { Store } from '@ngrx/store';

export class LiveSearch {
  public currentOptionIndex = -1;
  public statesFull = [];
  public inputPlaceholder = '';
  public form: FormGroup = this.formBuilder.group({ input: new FormControl('') });

  constructor(
    public router: Router,
    public formBuilder: FormBuilder,
    public nominatimService: NominatimService,
    public store: Store,
  ) {
    this.search();
  }

  search() {
    const initSearchParams: IInitSearchParams = JSON.parse(localStorage.getItem('InitSearchParams') ?? '{}');
    if (initSearchParams?.searchItemSelected) {
      this.inputPlaceholder = initSearchParams.searchParams?.name ?? initSearchParams.searchParams?.city ?? undefined;
    }

    this.form.controls.input.valueChanges
      .pipe(
        debounceTime(500),
        map((searchValue) => searchValue.trim()),
        distinctUntilChanged((a, b) => a === b),
        filter((searchValue) => searchValue !== '' && searchValue.length > 3),
        switchMap((searchValue) => this.nominatimService.searchAddress(searchValue)),
      )
      .subscribe((res) => {
        if (res.length >= 3) {
          this.statesFull = res
            .filter((item: any) => {
              const isRankBetween12And16 = item.place_rank > 12 && item.place_rank < 17;

              const hasCityOrSuburb =
                (item.address.hasOwnProperty('city') && item.osm_type === 'relation') ||
                (item.address.hasOwnProperty('suburb') && item.osm_type === 'relation');

              const hasTownOrVillage = item.address.hasOwnProperty('town') || item.address.hasOwnProperty('village');

              const isRank25AndPostcode = item.place_rank === 25 && item.type === 'postcode';

              const isRankBetween25And28 = item.place_rank > 25 && item.place_rank < 28;

              const isRankBetween28And31 = item.place_rank > 28 && item.place_rank < 31;

              const isTypeHouseOrResidential = item.type === 'house' || item.type === 'residential';

              return (
                (isRankBetween12And16 && (hasCityOrSuburb || hasTownOrVillage)) ||
                isRank25AndPostcode ||
                isRankBetween25And28 ||
                (isRankBetween28And31 && isTypeHouseOrResidential)
              );
            })
            .map((item) => this.mappingAddress(item));
        } else {
          this.statesFull = res.map((item) => this.mappingAddress(item));
        }
      });
  }

  mappingAddress(item: any): any {
    const address = [];
    address.push(item.address?.house_number);
    address.push(item.address?.residential ?? item.address?.road ?? item.address?.village ?? '');
    address.push(item.address?.town);
    address.push(item.address?.suburb);
    address.push(item.address?.city);
    address.push(item.address?.state);
    address.push(item.address?.postcode ? item.address?.postcode.replace(' ', '') : '');

    return { ...item, view: address.filter(Boolean).join(', ') };
  }

  // If not overridden in child class, this method will be called
  performSearch() {
    setTimeout(() => this.router.navigate(['/search']));
  }

  // If not overridden in child class, this method will be called
  selectAddressAction(item: any) {
    const city = item.address.city || item.address.town || item.address.village || item.address.suburb;
    const name = this.getNameFromAddressItem(item);
    this.inputPlaceholder = name;
    const searchParams: ISearchParams = { city, name };
    const zoom = 15; // defaultZoomMap is 15
    this.store.dispatch(SearchPageActions.setSearchParams({ searchParams, item, zoom }));
  }

  selectAddress(item: any) {
    this.selectAddressAction(item);
    this.performSearch();

    this.statesFull.length = 0;
    this.form.controls.input.setValue('');
  }

  getNameFromAddressItem(item: any): string {
    return item.namedetails?.name?.length
      ? item.namedetails?.name
      : item.display_name?.length && item.display_name.split(',').length > 1
        ? item.display_name.split(',').slice(0, 2)
        : undefined;
  }

  selectPreviousOption() {
    if (this.currentOptionIndex > 0) {
      this.currentOptionIndex--;
    }
  }

  selectNextOption() {
    if (this.currentOptionIndex < this.statesFull.length - 1) {
      this.currentOptionIndex++;
    }
  }

  selectCurrentOption() {
    if (this.currentOptionIndex >= 0 && this.currentOptionIndex < this.statesFull.length) {
      const selectedOption = this.statesFull[this.currentOptionIndex];
      this.form.controls.input.setValue(selectedOption.display_name);
      this.selectAddress(selectedOption);
    }
  }

  closeSearch() {
    this.statesFull.length = 0;
    this.form.controls.input.setValue('');
  }
}
