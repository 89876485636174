/**
 * Generates an array of DayInfo objects for the next 8 days, starting from today.
 * The dates are formatted according to the specified language code.
 *
 * @param {string} languageCode - The language code representing the desired language (e.g., 'en-US', 'sk-SK').
 * @param {number} countDays - The count days that need generate Default 8.
 * @returns {DayInfo[]} An array of DayInfo objects representing the next {countDays} days in the specified language format.
 */

import { DayInfo } from '@interfaces';

export function generatorDays(languageCode: string = 'en-US', countDays: number = 8): DayInfo[] {
  const lastHourInDay: number = 20;
  const days: DayInfo[] = [];

  let currentDate = new Date();
  if (currentDate.getHours() >= lastHourInDay) {
    currentDate.setDate(currentDate.getDate() + 1);
  }

  const options: Intl.DateTimeFormatOptions = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' };
  const dateFormatter = new Intl.DateTimeFormat(languageCode, options);

  for (let i = 0; i < countDays; i++) {
    const date = new Date(currentDate);
    date.setDate(date.getDate() + i);
    const month = date.getMonth() + 1;
    const formattedDate = dateFormatter.format(date);
    let [shortDayName, shortMonthName, day, fullYear] = formattedDate.split(' ');

    shortDayName = shortDayName.replace(',', '');
    day = day.replace(',', '');

    const fullDate = `${day}-${month.toString().padStart(2, '0')}-${fullYear}`;

    days.push({
      shortDayName,
      shortMonthName,
      day,
      fullDate,
    });
  }

  return days;
}
