import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Header } from '@dictionaries';
import { FilterNameEnum, LocalStorageEnum, ModalEnum, PropertyHomeTypeEnum } from '@enums';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, injectDestroyService, ModalsService, NominatimService, provideDestroyService } from '@service';
import * as FilterActions from '@store';
import * as SearchPageActions from '@store';
import * as HeaderActions from '@store';
import { selectHeaderLogged } from '@store';
import { BRAND_APP, LiveSearch } from 'app/shared/helpers';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from '../../shared/services/local-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [provideDestroyService()],
})
export class HeaderComponent extends LiveSearch implements OnInit {
  public headerMenu = new Header().menu();
  public activeChildMenu = null;
  public isLoggedUser: boolean = false;
  public readonly addNewItem = { name: this.translate.instant('header.sell'), path: '/sell-property' };

  protected readonly BRAND_APP = BRAND_APP;
  private readonly destroy$ = injectDestroyService();

  constructor(
    public router: Router,
    public auth: AuthService,
    public formBuilder: FormBuilder,
    public nominatimService: NominatimService,
    public store: Store,
    private translate: TranslateService,
    private modalsService: ModalsService,
    private localStorageService: LocalStorageService,
  ) {
    super(router, formBuilder, nominatimService, store);
  }

  ngOnInit(): void {
    this.store.pipe(select(selectHeaderLogged), takeUntil(this.destroy$)).subscribe((res) => {
      this.isLoggedUser = res;
    });
  }

  public openMobileMenu() {
    this.modalsService.open(ModalEnum.MOBILE_MENU);
  }

  public toggleMenu(submenu: any | undefined) {
    this.activeChildMenu = submenu;
  }

  public openAuthModal() {
    this.modalsService.open(ModalEnum.AUTH);
  }

  public goToAccount() {
    this.isLoggedUser ? this.router.navigate(['/myzex']) : this.openAuthModal();
  }

  public logout() {
    this.localStorageService.removeItem(LocalStorageEnum.ACCESS_TOKEN);
    this.store.dispatch(HeaderActions.updateLoggedState({ data: false }));
    this.isLoggedUser = false;
    this.router.navigate(['/']);
  }

  public setFilter(params?: { propertyType: string; homeType?: string }) {
    if (!params) {
      return;
    }

    let data = {
      [FilterNameEnum.propertyType]: params.propertyType,
      [FilterNameEnum.homeType]: params?.homeType
        ? [params.homeType]
        : Object.keys(PropertyHomeTypeEnum).map((k) => PropertyHomeTypeEnum[k]),
    };

    this.store.dispatch(FilterActions.filterChanged({ data }));
  }

  // If mobile header on main page - use original method to redirect to search page
  // Otherwise - override it to dispatch action to perform search
  override performSearch() {
    if (window.location.pathname.toLowerCase().includes('/search')) {
      this.store.dispatch(SearchPageActions.performSearch());
    } else {
      super.performSearch();
    }
  }
}
