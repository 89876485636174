import { Injectable } from '@angular/core';
import { EmailFrequencyEnum, FilterNameEnum, LocalStorageEnum } from '@enums';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import {
  AppState,
  selectCoordinatesRect,
  selectFilterValues,
  selectIsNarrowMode,
  selectIsShowMap,
  selectPageNumber,
  selectPropertiesList,
  selectPropertiesMap,
  selectSavedHomesIds,
  selectSearchParams,
  selectSortOrder,
} from '..';
import { select, Store } from '@ngrx/store';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { SavedSearchesService, SearchService } from '@service';
import { converterCoordinatesPolygon, propertyValuesToNumber, compareObjects } from '@helpers';
import {
  ISaveSearches,
  IPropertySearchListResponse,
  IPropertySearchParams,
  IPropertySearchMapResponse,
} from '@interfaces';
import * as SearchPageActions from '../actions/search-page.actions';

import { HttpResponse } from '@angular/common/http';
import { environment } from '@env';
import { LocalStorageService } from '../../shared/services/local-storage.service';

@Injectable()
export class SearchPageEffects {
  constructor(
    private actions: Actions,
    private store: Store<AppState>,
    private savedSearchesService: SavedSearchesService,
    private searchService: SearchService,
    private ls: LocalStorageService,
  ) {}

  filterChanged$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          SearchPageActions.filterChanged,
          SearchPageActions.changeCoordinatesRect,
          SearchPageActions.performSearch,
          SearchPageActions.resetAllFilters,
          SearchPageActions.setShowMap,
        ),
        debounceTime(200),
        concatLatestFrom(() => [
          this.store.pipe(select(selectFilterValues)),
          this.store.pipe(select(selectCoordinatesRect)),
          this.store.pipe(select(selectSearchParams)),
          this.store.pipe(select(selectSortOrder)),
          this.store.pipe(select(selectIsShowMap)),
          this.store.pipe(select(selectIsNarrowMode)),
          this.store.pipe(select(selectSavedHomesIds)),
          this.store.pipe(select(selectPropertiesList)),
          this.store.pipe(select(selectPropertiesMap)),
        ]),
        distinctUntilChanged((oldValue, newValue) => {
          const res =
            compareObjects(oldValue[1], newValue[1]) &&
            compareObjects(oldValue[2], newValue[2]) &&
            compareObjects(oldValue[3], newValue[3]) &&
            compareObjects(oldValue[4], newValue[4]) &&
            oldValue[5] === newValue[5] &&
            oldValue[6] === newValue[6] &&
            ((oldValue[8].length === newValue[8].length && !!newValue[8].length) ||
              (oldValue[9].length === newValue[9].length && !!newValue[9].length));

          if (res) {
            this.store.dispatch(SearchPageActions.clearPending());
          }

          return res;
        }),
        filter(([_, filterState, coordinatesRect, searchParams, sortOrder, isShowMap, isNarrowMode]) => {
          return (
            !!filterState &&
            (!!coordinatesRect ||
              !!searchParams?.city ||
              !!searchParams?.name ||
              !!this.ls.getItem(LocalStorageEnum.GeoUserInfo))
          );
        }),
        switchMap(
          ([_, filterState, coordinatesRect, searchParams, sortOrder, isShowMap, isNarrowMode, savedHomesIds]) => {
            const lsLocation = this.ls.getItem(LocalStorageEnum.GeoUserInfo);
            if (!coordinatesRect && !searchParams) {
              searchParams = {
                country: lsLocation?.country,
                city: lsLocation?.city,
              };
            }

            const paramsMap: IPropertySearchParams = this.searchService.preparePropertySearchParams(
              filterState,
              coordinatesRect,
              searchParams,
            );
            const paramsList: IPropertySearchParams = {
              ...paramsMap,
              page: 1,
              size: environment.propertiesLoadLimit,
              sort: +sortOrder,
            };

            const apiCalls: Observable<HttpResponse<any>>[] = [];

            if (!isNarrowMode) {
              apiCalls.push(this.searchService.propertySearchMap(paramsMap));
              apiCalls.push(this.searchService.propertySearchList(paramsList));
            } else {
              if (isShowMap) {
                apiCalls.push(this.searchService.propertySearchMap(paramsMap));
              } else {
                apiCalls.push(this.searchService.propertySearchList(paramsList));
              }
            }
            return forkJoin(apiCalls).pipe(
              map((responses) => {
                const listResponseAction = (response: HttpResponse<IPropertySearchListResponse>) => {
                  this.store.dispatch(
                    SearchPageActions.propertyListChanged(
                      response.status === 204
                        ? { count: 0, totalCount: 0, data: [] }
                        : this.searchService.updatePropertyListResponse(response.body, savedHomesIds),
                    ),
                  );
                };
                const mapResponseAction = (response: HttpResponse<IPropertySearchMapResponse>) => {
                  this.store.dispatch(
                    SearchPageActions.propertyMapChanged(
                      response.status === 204
                        ? { count: 0, totalCount: 0, data: [] }
                        : this.searchService.updatePropertyMapResponse(response.body),
                    ),
                  );
                };

                if (isNarrowMode) {
                  if (isShowMap) {
                    mapResponseAction(responses[0]);
                  } else {
                    listResponseAction(responses[0]);
                  }
                } else {
                  mapResponseAction(responses[0]);
                  listResponseAction(responses[1]);
                }
              }),
            );
          },
        ),
      ),
    { dispatch: false },
  );

  pageChanged$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(SearchPageActions.pageNumberChanged, SearchPageActions.sortOrderChanged),
        concatLatestFrom(() => [
          this.store.pipe(select(selectFilterValues)),
          this.store.pipe(select(selectCoordinatesRect)),
          this.store.pipe(select(selectSearchParams)),
          this.store.pipe(select(selectPageNumber)),
          this.store.pipe(select(selectSortOrder)),
          this.store.pipe(select(selectSavedHomesIds)),
        ]),
        distinctUntilChanged((oldValue, newValue) => {
          return (
            compareObjects(oldValue[1], newValue[1]) &&
            compareObjects(oldValue[2], newValue[2]) &&
            compareObjects(oldValue[3], newValue[3]) &&
            oldValue[4] === newValue[4] &&
            compareObjects(oldValue[5], newValue[5])
          );
        }),
        filter(([_, filterState, coordinatesRect, searchParams, pageNumber, sortOrder]) => {
          return !!filterState && (!!coordinatesRect || !!searchParams?.city || !!searchParams?.name);
        }),
        switchMap(([_, filterState, coordinatesRect, searchParams, pageNumber, sortOrder, savedHomesIds]) => {
          const params: IPropertySearchParams = this.searchService.preparePropertySearchParams(
            filterState,
            coordinatesRect,
            searchParams,
            sortOrder,
            pageNumber,
          );
          return this.searchService.propertySearchList(params).pipe(
            map((response: HttpResponse<IPropertySearchListResponse>) =>
              this.store.dispatch(
                SearchPageActions.propertyListChanged(
                  response.status === 204
                    ? { count: 0, totalCount: 0, data: [] }
                    : this.searchService.updatePropertyListResponse(response.body, savedHomesIds),
                ),
              ),
            ),
            catchError((error) => EMPTY),
          );
        }),
      ),
    { dispatch: false },
  );

  saveSearch$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(SearchPageActions.saveSearch),
        concatLatestFrom(() => [
          this.store.pipe(select(selectFilterValues)),
          this.store.pipe(select(selectCoordinatesRect)),
          this.store.pipe(select(selectSearchParams)),
          this.store.pipe(select(selectSortOrder)),
        ]),
        switchMap((data) => {
          const filterValues = Object.keys(data[1])
            .map((key) => {
              if (data[1][key] === true) {
                return { [key]: data[1][key] };
              }
              return { [key]: data[1][key] !== '0' ? data[1][key] : undefined };
            })
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

          const search: ISaveSearches = {
            coordinatesRect: converterCoordinatesPolygon(data[2]),
            filterValues: {
              ...propertyValuesToNumber(filterValues),
              [FilterNameEnum.bedroomExact]: filterValues[FilterNameEnum.bedroomExact] || undefined,
              [FilterNameEnum.bathroomExact]: filterValues[FilterNameEnum.bathroomExact] || undefined,
              [FilterNameEnum.mustHaveGarage]: filterValues[FilterNameEnum.mustHaveGarage] || undefined,
              [FilterNameEnum.hasAC]: filterValues[FilterNameEnum.hasAC] || undefined,
              [FilterNameEnum.hasBasement]: filterValues[FilterNameEnum.hasBasement] || undefined,
            },
            emailFrequency: EmailFrequencyEnum.INSTANTLY,
            sort: +data[4],
          };
          return this.savedSearchesService.save(search).pipe(
            map((res: { id: number }) => {
              this.store.dispatch(SearchPageActions.pendingSaveSearch({ data: false }));
              this.store.dispatch(SearchPageActions.waitingSaveSearch({ data: res.id }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
