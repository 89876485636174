import { Component, DestroyRef, EventEmitter, inject, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { generateHash } from '../../helpers/hash.utils';
import { ProfileService } from '@service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  public form: FormGroup;
  private destroyRef = inject(DestroyRef);

  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private toastrService: ToastrService,
  ) {
    this.createForm();
  }

  public submit() {
    this.profileService
      .forgotPassword(this.form.value.email, generateHash(this.form.value.email))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        () => {
          this.form.reset();
          this.toastrService.success(
            'We have sent a message with instructions to reset your password. Please check your email.',
          );
          this.closeModal.emit();
        },
        (error) => {
          this.toastrService.error(error.error.message);
        },
      );
  }

  private createForm() {
    this.form = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }
}
