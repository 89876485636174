export class Header {
  public headerMenu = [];

  constructor() {
    this.headerMenu = [
      this.createMenuItem('header.buy', '2', [
        this.createChildItem('header.buy_homes_for_sale', [
          this.createLink('header.buy_houses', '2', '2'),
          this.createLink('header.buy_condos', '2', '1'),
          this.createLink('header.buy_townhomes', '2', '5'),
          this.createLink('header.buy_multi_family', '2', '6'),
          this.createLink('header.buy_holiday_houses', '2', '4'),
        ]),
        this.createChildItem('header.buy_objects_for_sale', [
          this.createLink('header.buy_land', '2', '7'),
          this.createLink('header.buy_commercial', '2', '3'),
          this.createLink('header.buy_manufactured', '2', '8'),
          this.createLink('header.buy_objects', '2', '9'),
        ]),
      ]),
      this.createMenuItem('header.rent', '3', [
        this.createChildItem('header.rentals', [
          this.createLink('header.rent_houses', '3', '2'),
          this.createLink('header.rent_condos', '3', '1'),
          this.createLink('header.rent_townhomes', '3', '5'),
          this.createLink('header.rent_multi_family', '3', '6'),
          this.createLink('header.rent_holiday_houses', '3', '4'),
        ]),
        this.createChildItem('header.renter_nub', [
          this.createLink('header.rent_land', '3', '7'),
          this.createLink('header.rent_commercial', '3', '3'),
          this.createLink('header.rent_manufactured', '3', '8'),
          this.createLink('header.rent_objects', '3', '9'),
        ]),
      ]),
    ];
  }

  public menu() {
    return this.headerMenu;
  }

  private createMenuItem(name: string, propertyType: string, children: any[]) {
    return {
      name,
      path: '/search',
      params: { propertyType },
      child: children,
    };
  }

  private createChildItem(title: string, links: any[]) {
    return {
      title,
      links: [links],
    };
  }

  private createLink(name: string, propertyType: string, homeType: string) {
    return {
      name,
      path: '/search',
      params: { propertyType, homeType },
    };
  }
}
