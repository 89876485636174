<div>
  <app-slider
    size="for-map"
    [photos]="marker.images"
    [hidePhoto]="false"
    (click)="seeDetails($event)"
  ></app-slider>
  <div class="info">
    <p class="price" *ngIf="true">
      {{ marker.price | currency:  marker.priceCurrency :true:'1.0-0' }}
    </p>
    <div class="rooms">
      <span class="bedrooms">{{ marker.bedroom }}</span>
      <span class="bathrooms">{{ marker.bathroom }}</span>
    </div>
  </div>
</div>
