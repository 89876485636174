/**
 * Generates an array of time in 12-hour format with a specified time range and 30-minute step.
 * The first step will be the nearest time greater than or equal to the current time.
 *
 * @param {number} [startHour=8] - The starting hour of the time range (default is 8 AM).
 * @param {number} [endHour=20] - The ending hour of the time range (default is 8 PM).
 * @returns {string[]} An array of time strings in 12-hour format, e.g., ["08:30 am", "09:00 am", ...].
 */

export function generatorTimes(startHour: number = 8, endHour: number = 20): string[] {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();

  let firstStepHour = startHour;
  let firstStepMinute = 0;
  if (currentHour >= startHour) {
    firstStepHour = currentHour;
    firstStepMinute = currentMinute < 30 ? 30 : 0;
  }

  const timeArray: string[] = [];
  let hour = firstStepHour;
  let minute = firstStepMinute;

  while (hour < endHour || (hour === endHour && minute === 0)) {
    const period = hour >= 12 ? 'pm' : 'am';
    const displayHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
    const timeString = `${displayHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
    timeArray.push(timeString);

    minute += 30;
    if (minute >= 60) {
      minute = 0;
      hour++;
    }
  }

  // If timeArray is empty, fill it with the default range
  if (timeArray.length === 0) {
    hour = startHour;
    minute = 0;
    while (hour < endHour || (hour === endHour && minute === 0)) {
      const period = hour >= 12 ? 'pm' : 'am';
      const displayHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
      const timeString = `${displayHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
      timeArray.push(timeString);

      minute += 30;
      if (minute >= 60) {
        minute = 0;
        hour++;
      }
    }
  }

  return timeArray;
}
