import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SAVED_HOMES, SAVED_HOMES_LIST } from '../endpoints';

@Injectable({
  providedIn: 'root',
})
export class SavedHomesService {
  constructor(private http: HttpClient) {}

  public get() {
    return this.http.post(`${SAVED_HOMES_LIST}`, { page: 1, limit: 100 });
  }

  public add(id) {
    return this.http.put(`${SAVED_HOMES}/${id}`, null);
  }

  public delete(id) {
    return this.http.delete(`${SAVED_HOMES}/${id}`);
  }
}
