import { demoImages } from '../constants/property-dict';
import { environment } from '@env';

export function mapperDemoPhotosUtils(data: any) {
  return data.map((p) => {
    if (p.images === null && environment.useDemoPhotos) {
      const randomNumber = Math.floor(Math.random() * (10 - 1 + 1)) + 1;
      const demoPhotos = demoImages[randomNumber].map((img) => `${randomNumber}/${img}`);
      p = { ...p, images: demoPhotos, isDemoPhoto: true };
    }

    return p;
  });
}

export function mapperDemoPhotosUtilsSingle(data: any) {
  if (data.images === null && environment.useDemoPhotos) {
    const randomNumber = Math.floor(Math.random() * (10 - 1 + 1)) + 1;
    const demoPhotos = demoImages[randomNumber].map((img) => `${randomNumber}/${img}`);
    data = { ...data, images: demoPhotos, isDemoPhoto: true };
  }

  return data;
}
