<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>

<app-basic-modal [modalId]="ModalEnum.AUTH">
  <app-sign-in></app-sign-in>
</app-basic-modal>

<app-basic-modal [modalId]="ModalEnum.MOBILE_MENU">
  <app-mobile-menu></app-mobile-menu>
</app-basic-modal>

<app-basic-modal [modalId]="ModalEnum.SAVE_SEARCH">
  <app-save-searches></app-save-searches>
</app-basic-modal>

