import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TAKE_TOUR } from '../endpoints';
import { IBookingTour } from '../interfaces/booking-tour.interface';

@Injectable({
  providedIn: 'root',
})
export class TakeATourService {
  constructor(private http: HttpClient) {}

  public getTourDataMock() {
    return this.http.get('assets/mock/tour-data.json');
  }

  public getTourData(id: number) {
    return this.http.get(`${TAKE_TOUR}/${id}`);
  }

  public send(data: IBookingTour) {
    return this.http.post(TAKE_TOUR, data);
  }
}
