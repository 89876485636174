import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProvidersForImportInterface } from '@interfaces';
import { IMPORT_PROPERTIES } from '../endpoints';
import { environment } from '@env';

@Injectable()
export class ImportPropertiesService {
  constructor(private http: HttpClient) {}

  public importProperties(data: ProvidersForImportInterface): any {
    return this.http.post(IMPORT_PROPERTIES, data);
  }

  public deleteAllProperties(): any {
    return this.http.delete(`${environment.serverV3}/parse/delete-all-properties`);
  }

  public deleteFromS3(): any {
    return this.http.post(`${environment.serverV3}/parse/delete-from-s3`, {});
  }
}
