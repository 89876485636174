import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenerateDemoDataService } from '../../shared/services/generate-demo-data.service';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss'],
})
export class TestPageComponent implements OnInit {
  title = 'Test page';

  constructor(
    private readonly router: Router,
    private generateDemoData: GenerateDemoDataService,
  ) {}

  ngOnInit() {
    this.title = this.router.url.replace('/', '');
    this.generateDemoData.generateAddress();
  }
}
