import { environment } from '@env';

// services
const VERSION = 'v3';
export const HEALTH = `${environment.server}/health`;
export const GEOIP = 'https://ipinfo.io';
export const NOMINATIM = 'https://nominatim.openstreetmap.org/search.php';

export const SEARCH_PROPERTIES = `${environment.server}/api/${VERSION}/properties/search`;
export const USER_PROPERTIES = `${environment.server}/api/${VERSION}/user/properties`;
export const SEARCH_MAP_PROPERTIES = `${environment.server}/api/${VERSION}/properties/markers`;
export const PROPERTY = `${environment.server}/api/${VERSION}/properties`;
export const SIMILAR_PROPERTIES = `${environment.server}/api/${VERSION}/properties`;
export const LAST_VIEWS_PROPERTIES = `${environment.server}/api/${VERSION}/properties/list`; // last views properties (max 15 items)

export const SIGN_IN = `${environment.server}/api/${VERSION}/sign-in`;
export const REGISTER = `${environment.server}/api/${VERSION}/sign-up`;
export const LOGOUT = `${environment.server}/api/${VERSION}/logout`;
export const SIGN_OUT = `${environment.server}/api/${VERSION}/sign-out`;
export const FORGOT = `${environment.serverV3}/user/forgot-password`;
export const REFRESH_TOKEN = `${environment.server}/api/${VERSION}/refresh-token`;

export const SAVED_SEARCHES = `${environment.server}/api/${VERSION}/saved-searches`;
export const SAVED_HOMES_LIST = `${environment.server}/api/${VERSION}/saved-homes/list`;
export const SAVED_HOMES = `${environment.server}/api/${VERSION}/saved-homes`;

export const BROKER_INFO = `${environment.server}/api/${VERSION}/broker-info`;

export const ACCOUNT_PROFILE = `${environment.server}/api/${VERSION}/user/profile`;
export const ACCOUNT_PROFILE_SET_PASS = `${environment.server}/api/${VERSION}/set-password`;
export const ACCOUNT_PROFILE_UPDATE_PASS = `${environment.serverV3}/user/reset-password`;
// TODO: Create api ticket
export const ACCOUNT_PROFILE_AVATAR = `${environment.server}/api/${VERSION}/user/profile/avatar`;
export const ACCOUNT_PROFILE_VERIFY_EMAIL = `${environment.server}/api/${VERSION}/user/profile/verify-email`;
export const ACCOUNT_PROFILE_VERIFY_PHONE = `${environment.server}/api/${VERSION}/user/profile/verify-phone`;
export const ACCOUNT_PROFILE_DEACTIVATE = `${environment.server}/api/${VERSION}/user/profile/deactivate`;
// TODO: Create api ticket
export const ACCOUNT_PROFILE_LINK_SOCIAL = `${environment.server}/api/${VERSION}/user/profile/social`;

export const CONTACT_BUYER = `${environment.server}/api/${VERSION}/contact-buyer`;

export const TAKE_TOUR = `${environment.server}/api/${VERSION}/take-tour`;

// TODO: Create api ticket
export const SHARED_LINK = `${environment.server}/api/${VERSION}/shared-link`;

export const CONFIRMATION_EMAIL = `${environment.server}/api/${VERSION}/confirmation`;

export const IMPORT_PROPERTIES = `${environment.serverV3}/parse/import-properties`;
