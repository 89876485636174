import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TestPageComponent } from './pages/test-page/test-page.component';
import { YourPropertyDetailsComponent } from './pages/cabinet/modules/your-homes/property-details/your-property-details.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then((module) => module.HomeModule),
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then((module) => module.SearchModule),
  },
  {
    path: 'myzex',
    loadChildren: () => import('./pages/cabinet/cabinet.module').then((module) => module.CabinetModule),
  },
  {
    path: 'confirmation/:type/:token',
    loadChildren: () => import('./pages/confirmation/confirmation.module').then((module) => module.ConfirmationModule),
  },
  {
    path: 'change-password/:token',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then((module) => module.ChangePasswordModule),
  },
  {
    path: 'property/:id/:address',
    loadChildren: () =>
      import('./shared/modules/property-details/property-details.module').then(
        (module) => module.PropertyDetailsModule,
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then((module) => module.PrivacyPolicyModule),
  },
  {
    path: 'sell-property',
    component: YourPropertyDetailsComponent,
  },
  { path: 'help', component: TestPageComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
